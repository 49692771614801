<template>
    <app-layout>
        <template v-slot:header>
            <Header :title="$t('collective_consultant_appointment')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus=$event">
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                        <b-form-checkbox
                            :id="'checbox-'+key"
                            :name="'checbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </template>
            </Header>
        </template>
        <template v-slot:header-mobile>
            <header-mobile :title="$t('collective_consultant_appointment')"
                           :isColumns="true"
                           @filter-div-status="datatable.filterStatus=$event">
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                        <b-form-checkbox
                            :id="'checbox-'+key"
                            :name="'checbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </template>
            </header-mobile>
        </template>

        <div>
            <datatable-filter v-show="true"
                              @filter="filter"
                              @filterClear="filterClear">
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-col cols="12" sm="6">
                            <ValidationProvider :name="student_number_error" v-slot="{errors}">
                                <b-form-group :label="$t('student_numbers')">
                                    <multi-text v-model="datatable.queryParams.filter.student_number"
                                        :validate-error="errors[0]"
                                        :only-number-end-enter="true"/>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="filter.advisor_id" v-slot="{ errors}">
                                <b-form-group :label="$t('teaching_staff')">
                                    <staff-auto-suggest v-model="datatable.queryParams.filter.advisor_id"
                                        :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <ValidationProvider name="filter.faculty_code" :rules="datatable.queryParams.filter.student_number || datatable.queryParams.filter.advisor_id ? '' : 'required'" v-slot="{ errors}">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"
                                        :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="filter.program_code" :rules="datatable.queryParams.filter.student_number || datatable.queryParams.filter.advisor_id ? '' : 'required'" v-slot="{ errors}">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                        :faculty_code="datatable.queryParams.filter.faculty_code"
                                        :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="filter.class" v-slot="{ errors}">
                                <b-form-group :label="$t('class')">
                                    <classes-selectbox v-model="datatable.queryParams.filter.class"
                                        :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>

            <datatable :isLoading="datatable.isLoading"
                @on-selected-rows-change="selectedRows"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :queryParams="datatable.queryParams"
                :select-options="!!datatable.rows.length"
                v-show="true"/>
            <ValidationObserver ref="formValidate">
                <b-row class="py-5 align-items-center">
                    <b-col cols="12" sm="2" class="d-flex align-items-center">
                        <h5 class="m-0">{{ $t('teaching_staff') + ':' }}</h5>
                    </b-col>
                    <b-col cols="12" sm="4" class="py-4">
                        <ValidationProvider name="advisor_id" rules="required" v-slot="{ errors}">
                            <staff-auto-suggest v-model="advisor_id" :validate-error="errors[0]" open-direction="top"/>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="2">
                        <b-button variant="primary" class="mr-2" @click="save">{{ $t('save') }}</b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </div>
    </app-layout>

</template>

<script>

import Header from "@/layouts/AppLayout/Header.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

import DatatableFilter from "@/components/datatable/DatatableFilter.vue";
import Datatable from "@/components/datatable/Datatable.vue";

import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import ClassesSelectbox from "@/components/interactive-fields/ClassesSelectbox.vue";
import StaffAutoSuggest from "@/components/interactive-fields/StaffAutoSuggest.vue";
import MultiText from "@/components/interactive-fields/MultiText.vue";

import AdvisorBulk from "@/services/AdvisorBulk";

import qs from "qs";
import {ValidationProvider, ValidationObserver} from "vee-validate"

export default {
    name: "advisorBulk",
    components: {
        ClassesSelectbox,
        StaffAutoSuggest,
        ProgramSelectbox,
        FacultySelectbox,
        MultiText,
        Datatable,
        DatatableFilter,
        HeaderMobile,
        AppLayout,
        Header,
        ValidationProvider, ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t('collective_consultant_appointment')
        };
    },
    data() {

        return {
            student_number_error: '',
            selectedData: [],
            advisor_id: null,
            datatable: {
                showTable: false,
                isLoading: false,
                queryParams: {
                    filter: {
                        student_number: '',
                    },
                    page: 1,
                    limit: 20
                },
                rows: [],
                columns: [
                    {
                        label: this.$t('student_number').toUpper(),
                        field: 'student_number',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('name').toUpper(),
                        field: 'name',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('surname').toUpper(),
                        field: 'surname',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('faculty').toUpper(),
                        field: this.getLocaleField('faculty'),
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('program').toUpper(),
                        field: this.getLocaleField('program'),
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('class').toUpper(),
                        field: 'class',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('advisor').toUpper(),
                        field: 'advisor',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },

                ]
            }
        }
    },
    methods: {
        async filter() {
            const valid = await this.$refs.formModalValidate.validate()
            if(!valid) {
                return
            }
            this.getRows()
        },
        filterClear() {
            this.datatable.queryParams.filter = {}
        },
        getRows() {
            this.datatable.isLoading = true;
            var configStudentNumber = null;
            if (this.datatable.queryParams.filter.student_number) {
                configStudentNumber = this.datatable.queryParams.filter.student_number.split(',')
            }

            const config = {
                params: {
                    filter: {
                        ...this.datatable.queryParams.filter,
                        student_number: configStudentNumber
                    },
                    page: this.datatable.queryParams.page,
                    limit: this.datatable.queryParams.limit
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            AdvisorBulk.getAll(config)
                .then(res => {
                    if(res.data.success) {
                        this.datatable.rows = res.data.data
                    }
                })
                .catch(err => {
                    let newErrorStudentNumber = Object.keys(err.data.errors).find(key => key.includes('filter.student_number'));
                    this.student_number_error = newErrorStudentNumber;
                    setTimeout(()=>{
                        this.showErrors(err, this.$refs.formModalValidate,false,true)
                    },300)
                })
                .finally(() => {
                    this.datatable.showTable = true;
                    this.datatable.isLoading = false;
                })
        },
        selectedRows(data) {
            if (data != null) {

                this.selectedData = data.selectedRows
            }
        },
        configData() {
            if (this.selectedData != null && Array.isArray(this.selectedData)) {
                return this.selectedData.map(x => x.id)
            }
        },
        async save() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                let selectedStudents_IDS = this.configData();
                if (selectedStudents_IDS != null) {
                    let formData = new FormData();
                    formData.append('advisor_id', this.advisor_id);

                    for (const [i, value] of [...(selectedStudents_IDS || [])].entries()) {
                        formData.append(`student_program_ids[${i}]`, value)
                    }

                    AdvisorBulk.store(formData)
                        .then(() => this.$toast.success(this.$t('proccess_success')))
                        .catch(err => this.showErrors(err, this.$refs.formValidate))
                    return
                } else {
                    return this.$toast.error(this.$t('students') + ' ' + this.$t('not_selected'))
                }
            }
        }
    },
}
</script>
